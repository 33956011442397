import React from "react"
import { Link } from "gatsby"
import SecHeader from "../components/secondHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"

import events_banner from '../images/events_banner.png'



const Events = () => (
  <Layout>
    <SecHeader pageTitle="Events" image={events_banner}/>
    <SEO title="Page two" />

    <div class="uk-container uk-flex uk-flex-center">

        

   
<iframe src="https://calendar.google.com/calendar/b/1/embed?height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=5f5pd7mabsqc8ocsp2n7hdmp74%40group.calendar.google.com&amp;color=%23FFFFFF&amp;ctz=America%2FDetroit" style={{border:'0'}} width="900" height="700" frameborder="0" scrolling="no"></iframe>            
        

    </div>
   
  </Layout>
)

export default Events